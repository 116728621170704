import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { DraftPointModel, DriversBatchValue } from '../models';

export const draftPointsActions = createActionGroup({
    source: 'Planning - Draft Points',
    events: {
        'Get Draft Points': props<{ silent: boolean }>(),
        'Get Draft Points Success': props<{ payload: DraftPointModel[] }>(),
        'Get Draft Points Error': emptyProps(),
        'Add Drivers Batch': props<{ value: DriversBatchValue }>(),
        'Add Drivers Batch Success': emptyProps(),
        'Add Drivers Batch Error': emptyProps(),
        Clear: emptyProps(),
        ClearSuccess: emptyProps(),
    },
});
