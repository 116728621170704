import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ApiResponse } from '@app/shared/models';
import { DraftPointDto, DraftPointModel, DriversBatchValue } from '../models';
import { PlanningQuery } from '../../models';
import { DriversBatchModel } from '../models/drivers-batch.model';

@Injectable({
    providedIn: 'root',
})
export class HttpService {
    constructor(private httpClient: HttpClient) {}

    getDraftPoints(query: PlanningQuery): Observable<DraftPointModel[]> {
        const params = {
            'planningType[eq]': query.type,
            'date[eq]': query.date,
        };
        return this.httpClient
            .get<ApiResponse<DraftPointDto[]>>(`draft-points`, { params: new HttpParams({ fromObject: params }) })
            .pipe(map((response) => response.data.map((item) => new DraftPointModel(item))));
    }

    addDriversBatch(value: DriversBatchValue): Observable<DriversBatchModel> {
        const params = new DriversBatchModel(value);
        return this.httpClient.post<ApiResponse<DriversBatchModel>>(`draft-points/batch`, params).pipe(map((response) => response.data));
    }
}
