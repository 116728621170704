import { Injectable } from '@angular/core';
import { filter } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectDraftPoint, selectDraftPoints, selectDraftPointsByIds, selectLoaded, selectLoading, selectSuccess } from '../selectors/draft-points.selector';
import { draftPointsActions } from '../actions/draft-points.actions';
import { DraftPointModel, DriversBatchValue } from '../models';

@Injectable({
    providedIn: 'root',
})
export class DraftPointsFacade {
    draftPoints$ = this.store.select(selectDraftPoints).pipe(filter((data): data is DraftPointModel[] => data !== null));
    loaded$ = this.store.select(selectLoaded).pipe(filter((data): data is boolean => data !== null));
    loading$ = this.store.select(selectLoading).pipe(filter((data): data is boolean => data !== null));
    success$ = this.store.select(selectSuccess).pipe(filter((data): data is boolean => data !== null));

    draft$ = (id: string) => {
        return this.store.select(selectDraftPoint(id)).pipe(filter((data): data is DraftPointModel => data !== null));
    };

    constructor(private store: Store) {}

    clear(): void {
        this.store.dispatch(draftPointsActions.clear());
    }

    draftPointsByIds$(ids: string[]) {
        return this.store.select(selectDraftPointsByIds(ids)).pipe(filter((data): data is DraftPointModel[] => data !== null));
    }

    addDriversBatch(value: DriversBatchValue) {
        this.store.dispatch(draftPointsActions.addDriversBatch({ value }));
    }

    clearSuccess(): void {
        this.store.dispatch(draftPointsActions.clearSuccess());
    }
}
