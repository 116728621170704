import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DraftPointsState } from '../reducers/draft-points.reducer';
import { DRAFT_POINTS_KEY } from '../keys';

export const selectState = createFeatureSelector<DraftPointsState>(DRAFT_POINTS_KEY);
export const selectDraftPoints = createSelector(selectState, (state) => state.draftPoints);
export const selectDraftPoint = (id: string) => createSelector(selectState, (state) => state.draftPoints?.find((draftPoint) => draftPoint.id === id) || null);
export const selectLoading = createSelector(selectState, (state) => state.loading);
export const selectLoaded = createSelector(selectState, (state) => state.loaded);
export const selectSuccess = createSelector(selectState, (state) => state.success);

export const selectDraftPointsByIds = (ids: string[]) =>
    createSelector(selectState, (state) => state.draftPoints?.filter((draftPoint) => ids.includes(draftPoint.id)) || null);
