import { createReducer, on } from '@ngrx/store';
import { draftPointsActions } from '../actions/draft-points.actions';
import { DraftPointModel } from '../models';

export interface DraftPointsState {
    draftPoints: DraftPointModel[] | null;
    total: number | null;
    loading: boolean | null;
    loaded: boolean | null;
    success: boolean | null;
}

export const initialState: DraftPointsState = {
    draftPoints: null,
    total: null,
    loading: null,
    loaded: null,
    success: null,
};

export const draftPointsReducer = createReducer(
    initialState,
    on(
        draftPointsActions.getDraftPoints,
        (state): DraftPointsState => ({
            ...state,
            loading: true,
        }),
    ),
    on(
        draftPointsActions.getDraftPointsSuccess,
        (state, action): DraftPointsState => ({
            ...state,
            draftPoints: action.payload,
            loaded: true,
            loading: false,
        }),
    ),
    on(
        draftPointsActions.getDraftPointsError,
        (state): DraftPointsState => ({
            ...state,
            loading: false,
            loaded: false,
            success: false,
        }),
    ),
    on(
        draftPointsActions.addDriversBatch,
        (state): DraftPointsState => ({
            ...state,
            loading: true,
            loaded: false,
        }),
    ),
    on(
        draftPointsActions.addDriversBatchSuccess,
        (state): DraftPointsState => ({
            ...state,
            loading: false,
            loaded: true,
            success: true,
        }),
    ),
    on(
        draftPointsActions.addDriversBatchError,
        (state): DraftPointsState => ({
            ...state,
            loading: false,
            loaded: false,
            success: false,
        }),
    ),

    on(
        draftPointsActions.clearSuccess,
        (state): DraftPointsState => ({
            ...state,
            success: false,
        }),
    ),
    on(
        draftPointsActions.clear,
        (state): DraftPointsState => ({
            ...state,
            ...initialState,
        }),
    ),
);
