import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ApiResponse } from '@app/shared/models';
import { DriverDto, DriverModel } from '../models';

@Injectable({
    providedIn: 'root',
})
export class HttpService {
    constructor(private httpClient: HttpClient) {}

    getDrivers(): Observable<DriverModel[]> {
        return this.httpClient.get<ApiResponse<DriverDto[]>>(`drivers/planning`).pipe(map((response) => response.data));
    }
}
