import { DriversBatchValue } from './drivers-batch.dto';

export class DriversBatchModel {
    date: string;
    planningType: number;
    driverIds: string[];

    constructor(data: DriversBatchValue) {
        this.date = data.date;
        this.planningType = Number(data.planningType);
        this.driverIds = data.driverIds;
    }
}
